@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700,800,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap');

@import './bootstrap/bootstrap.scss';
@import './fontawesome/fontawesome.scss';
@import './layout/fonts.scss';

@import './layout/reset.scss';
@import './layout/spacing.scss';
@import './layout/header.scss';
@import './layout/footer.scss';

@import './pages/pages.scss';

@import './lws-reviews.scss';